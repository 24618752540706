export const actionsList = [
  {
    heading: '挑戦',
    text: '挑戦しよう。<br class="pc:hidden"/>新たな一歩を踏み出すことに価値がある。',
  },
  {
    heading: 'スピード',
    text: 'スピードは品質だ。<br class="pc:hidden"/>期限を決めて早く取り組もう。',
  },
  {
    heading: '期待を超える',
    text: 'お客様の期待を超える仕事をしよう。',
  },
  {
    heading: '発言と行動',
    text: '事業をつくる当事者としての<br class="pc:hidden"/>発言と行動にこだわろう。',
  },
  {
    heading: '仲間',
    text: ' 仲間を知ろう。感謝しよう。<br class="pc:hidden"/>承認しよう。伝え合おう。',
  },
  {
    heading: 'コミュニケーション',
    text: '気づきと気遣いのある<br class="pc:hidden"/>コミュニケーションをとろう。',
  },
]
