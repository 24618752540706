import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { SEOIR } from '../../../components/data/SEO'
import { Philosophy } from '../../../components/organisms/Philosophy/Philosophy'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  return (
    <LayoutIR
      heading="企業理念・行動指針"
      headingEnglish={ENGLISH_TITLE.Philosophy}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '経営方針', url: '/ir/policy' },
        { title: '企業理念・行動指針', url: '' },
      ]}
    >
      <Philosophy />
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="企業理念・行動指針" url="/ir/policy/philosophy" />
)
