interface Props {
  number: number
  heading: string
  text: string
}

/**
 * 企業理念・行動指針に使用
 * ulでラップして使用。
 * @param {number} number 赤丸の番号です。
 * @param {string} heading 見出しです。
 * @param {string} text テキストです。
 */
/** */

export const ListCourseOfAction = ({ text, number, heading }: Props) => {
  return (
    <li className="list-none flex pc:flex-row flex-col items-center pc:items-start">
      <span className="w-[52px] h-[52px] flex-shrink-0 rounded-full border border-primary text-primary flex items-center justify-center font-Rajdhani font-medium text-md">
        {number}
      </span>
      <div className={`pc:ml-4 text-center pc:text-left mt-3 pc:mt-0`}>
        <h4 className="font-bold leading-tight text-xl">{heading}</h4>
        <p
          className="pc:mt-2 mt-1 text-md leading-normal"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </li>
  )
}
