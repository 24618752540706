import { HeadingTertiary } from '../../atoms/Heading/HeadingTertiary'
import { actionsList } from '../../data/actionsList'
import { ListCourseOfAction } from '../../molecules/List/ListCourseOfAction'

export const Philosophy = () => {
  return (
    <div>
      <section className="bg-white pc:py-12 pc:px-8">
      <HeadingTertiary label="企業理念" />
        <div className="hidden pc:block pt-14">
          <img src="/common/img/philosophy.jpg" alt="成長を愉しもう。" />
        </div>
        <div className="pc:hidden">
          <img src="/common/img//philosophy2.png" alt="成長を愉しもう。" />
        </div>
      </section>
      <section className="mt-20 bg-white pc:py-12 pc:px-8">
        <HeadingTertiary label="行動指針" />
        <div className="pc:flex pc:justify-between pc:pt-2">
          <div className="pt-14">
            <ul className="space-y-12 pc:space-y-14">
              {actionsList.map((action, i) => (
                <ListCourseOfAction key={i} number={i + 1} {...action} />
              ))}
            </ul>
          </div>
          <div className="pt-14">
            <div className="w-[338px] mb-10 pc:mb-20 pc:block hidden">
              <img src="/common/img/philosophy3.jpg" alt="" />
            </div>
            <div className="mb-10 pc:mb-20 pc:hidden">
              <img src="/common/img/philosophy4.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
